import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import SEO from "../components/seo";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import {
  FaCheck,
  FaHandHolding,
  FaUsers,
  FaShoppingCart,
  FaRegMoneyBillAlt,
  FaBriefcase,
  FaChartLine,
} from "react-icons/fa";
import Columns from "../components/columns";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Tile from "../components/Tile";
import Container from "../components/container";
import { Link } from "gatsby";
import * as styles from "./index.module.css";

export const query = graphql`
  {
    sla: file(relativePath: { eq: "umowa-sla-questy.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Wsparcie = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Nasze rozwiązania"
      headline="Kompleksowe wsparcie dla przedsiębiorstwa"
    >
      <SEO
        title="Rozwiązania dla biznesu - kompleksowe wsparcie firmy"
        description="Rozwiązania dla biznesu ✅ Rozwiązania dedykowane • Systemy do zarządzania firmą • Systemy CRM • Platformy B2B • Systemy do serwisu"
        keywords={["rozwiązania dla biznesu"]}
      />

      <p>
        Zanim poznasz nasze <strong>rozwiązania dla biznesu</strong>, chcemy
        podkreślić, że spełniamy nawet <strong>najbardziej niestandardowe potrzeby</strong> naszych klientów.
        Jest to możliwe dzięki naszemu{" "}
        <strong>wieloletniemu doświadczeniu oraz elastyczności</strong>, która
        jest główną cechą naszych produktów. Wystarczy, że powiesz nam, czego
        oczekujesz, a my bez problemu <strong>dostosujemy rozwiązanie</strong> do Twojego
        biznesu.
      </p>
      <p style={{
        paddingBottom: 30,
      }}>
        Co jeszcze możemy zaoferować? Wiemy, że firmy naszych klientów znajdują
        się na różnych etapach rozwoju i potrzebują wtedy różnych narzędzi.
        Wybierając mniejsze rozwiązanie,{" "}
        <strong> nie tracisz możliwości jego rozwoju w przyszłości</strong>.
        Chętnie <strong>pomożemy Ci rozbudowywać system</strong> wraz ze
        wzrostem i rozwojem Twojego przedsiębiorstwa.
      </p>

      <Container className="container container--text">

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
        <div className="text-answers">

         <div className="text-block">
         <Link to="/sprzedaz-i-marketing/"> <Tile to="/sprzedaz-i-marketing/">
            <FaRegMoneyBillAlt size="70px" />
            <h3>Sprzedaż i marketing</h3>
            <p>
              W naszej ofercie znajdują się systemy CRM zarówno dla dużych lub
              mających niestandardowe potrzeby przedsiębiorstw, jak i dla
              mniejszych firm, które dopiero się rozwijają. Wybierz system
              odpowiedni dla Twojego biznesu i już dziś zadbaj o&nbsp;relacje z
              klientami!
            </p>
          </Tile></Link>
           </div>

  <div className="text-block">
          <Link to="/samoobsluga-b2b/"><Tile to="/samoobsluga-b2b/">
            <FaShoppingCart size="70px" />
            <h3>Samoobsługa B2B</h3>
            <p>
              W obszarze automatyzacji sprzedaży dysponujemy dwoma
              rozwiązaniami. Jedno z nich to dedykowane, wzbogacone
              o wiele innych modułów pozwalających na skuteczne zarządzanie
              całym biznesem. Drugie to gotowe, odpowiadające na standardowe
              potrzeby.
            </p>
          </Tile></Link>
          </div>

  <div className="text-block">
          <Link to="/zarzadzanie-uslugami/"><Tile to="/zarzadzanie-uslugami/">
            <FaBriefcase size="70px" />
            <h3>Zarządzanie usługami</h3>
            <p>
              Opracowaliśmy rozwiązania wspierające zarządzanie serwisem,
              usługami, zleceniami produkcyjnymi czy reklamacjami. Jesteśmy w
              stanie zagwarantować system adekwatny zarówno dla dużych, średnich jak i
              mniejszych firm, działających w wielu różnych branżach.{" "}
            </p>
          </Tile></Link>
          </div>
        </div>
        </div>
      </Container>
      
  <Container>
  <div>
          <Link to="/rozwiazania-dedykowane/"><Tile to="/rozwiazania-dedykowane/">
            <FaHandHolding size="70px" />
            <h3>Rozwiązania dedykowane</h3>
            <p>
              Mamy wyjątkowo rozległe doświadczenie w&nbsp;dostarczaniu rozwiązań
              biznesowych dla dużych firm z&nbsp;niestandardowymi potrzebami.
              Jesteśmy w&nbsp;stanie zapewnić to, czego nie oferuje większość
              dostępnych na rynku rozwiązań. Stawiamy na elastyczność i&nbsp;dostosowanie 
              – bez problemu dopasujemy technologię do wizji rozwoju Twojego
              biznesu.
            </p>
          </Tile></Link>
          </div>
      </Container>

      <center>
        <h4 style={{
        paddingTop: 20,
      }}>
          Zainteresowały Cię nasze rozwiązania dla biznesu? Odezwij się do nas.
          <br />Chętnie porozmawiamy o możliwościach rozwoju Twojej firmy.
        </h4>
        <Container>
          <div
            style={{
              textAlign: "center",
              margin: "10px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
             Przejdź do kontaktu
            </Link>
          </div>
        </Container>
      </center>
    </FunctionPageLayout>
  );
};

export default Wsparcie;
